import { Sidebar as WdsSidebar } from "@datamole/wds-component-sidebar";
import clsx from "clsx";
import { ReactNode } from "react";
import { NavLink } from "react-router-dom";

import styles from "@/components/layout/sidebar/sidebar-item/sidebar-item.module.css";

export interface SidebarItemProps {
    name: string;
    to: string;
    icon?: ReactNode;
    isActive?: (url: URL) => boolean;
    iconVariant?: "default" | "light";
}

export const SidebarItem = (props: SidebarItemProps) => {
    const { to, icon, name, iconVariant = "default", isActive } = props;

    const url = new URL(window.location.href);

    return (
        <WdsSidebar.Item asChild className={clsx({ [styles.inactivable]: isActive && !isActive(url) })}>
            <NavLink to={to}>
                {icon && (
                    <WdsSidebar.Item.Icon
                        className={clsx(styles.icon, {
                            [styles[`icon_${iconVariant}`]]: iconVariant !== "default",
                            [styles.icon_active]: isActive?.(url),
                        })}
                    >
                        {icon}
                    </WdsSidebar.Item.Icon>
                )}
                <WdsSidebar.Item.Content>{name}</WdsSidebar.Item.Content>
            </NavLink>
        </WdsSidebar.Item>
    );
};
