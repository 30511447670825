import { Sidebar as WdsSidebar, useCollapsible } from "@datamole/wds-component-sidebar";
import clsx from "clsx";

import { SidebarItem } from "@/components/layout/sidebar/sidebar-item";
import { SidebarItemProps } from "@/components/layout/sidebar/sidebar-item/sidebar-item";
import styles from "@/components/layout/sidebar/sidebar-item/sidebar-item.module.css";
import { Spinner } from "@/components/spinner";

export type CollapsibleSidebarItemProps = SidebarItemProps & {
    subitems: SidebarItemProps[];
    isSidebarCollapsed: boolean;
    isInitiallyExpanded?: boolean;
    isSubitemsLoading?: boolean;
};

export const CollapsibleSidebarItem = (props: CollapsibleSidebarItemProps) => {
    const { icon, name, subitems, isInitiallyExpanded, isSidebarCollapsed, isSubitemsLoading } = props;
    const { createCollapsibleProps, createCollapsibleToggleProps } = useCollapsible({
        isInitiallyExpanded,
    });

    const { isCollapsibleExpanded, ...restCollapsibleProps } = createCollapsibleProps();
    const isExpanded = !isSidebarCollapsed && isCollapsibleExpanded;

    const url = new URL(window.location.href);
    const isActive = props.isActive?.(url) ?? false;

    return (
        <>
            <WdsSidebar.Item {...createCollapsibleToggleProps()} className={clsx(!isActive && styles.inactivable)}>
                {icon && <WdsSidebar.Item.Icon className={clsx(styles.icon)}>{icon}</WdsSidebar.Item.Icon>}
                <WdsSidebar.Item.Content>{name}</WdsSidebar.Item.Content>
                <WdsSidebar.Collapsible.Indicator isCollapsibleExpanded={isExpanded} />
            </WdsSidebar.Item>

            <WdsSidebar.Collapsible isCollapsibleExpanded={isExpanded} {...restCollapsibleProps}>
                {isSubitemsLoading ? (
                    <Spinner size="sm" />
                ) : (
                    subitems.map((subitem: SidebarItemProps, idx: number) => <SidebarItem key={idx} {...subitem} />)
                )}
            </WdsSidebar.Collapsible>
        </>
    );
};
