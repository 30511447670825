import { Button } from "@datamole/wds-component-button";
import { Menu } from "@datamole/wds-component-menu";
import { Sidebar } from "@datamole/wds-component-sidebar";
import { Link } from "react-router-dom";

import { QuickLinkIcon, LinkOutIcon } from "@/components/icon";
import styles from "@/components/layout/sidebar/quick-links-menu/quick-links-menu.module.css";

const quickLinks = [
    {
        title: "Dairy Detective",
        url: "https://dairydetective.apps.fonterra.com/",
    },
    {
        title: "Trace-It",
        url: "https://ca-prod.fonterra.com/apxprd/f?p=137:1:4299893914139:::::",
    },
    {
        title: "eQual",
        url: "http://nzaklevfn500:8080/equal/",
    },
    {
        title: "Pirana",
        url: "http://dcprodaw215/Pirana/ShireLogin.aspx?",
    },
    {
        title: "Co-op GPT",
        url: "https://chatgpt.apps.fonterra.com/",
    },
    {
        title: "FRDC myGPT",
        url: "https://mygpt.frdc.fonterra.com/",
    },
    {
        title: "Pilot Plant Planning app",
        url: "https://fonterra.sharepoint.com/sites/FRDCPortal2/SitePages/Book-a-Pilot-Plant-Trial.aspx",
    },
];

export const QuickLinksMenu = () => {
    return (
        <Menu>
            <Menu.Trigger asChild className="rd_button">
                <Sidebar.Item>
                    <Sidebar.Item.Icon>
                        <QuickLinkIcon className={styles.quick_links_menu_icon} />
                    </Sidebar.Item.Icon>
                    <Sidebar.Item.Content>Quick Links</Sidebar.Item.Content>
                </Sidebar.Item>
            </Menu.Trigger>
            <Menu.Content
                align="end"
                side="right"
                className={styles.menu_content}
                onOpenAutoFocus={(ev) => {
                    ev.preventDefault();
                }}
            >
                <Menu.Content.List>
                    {quickLinks.map((link, index) => (
                        <Menu.Content.Item key={index} asChild>
                            <Button as={Link} to={link.url} target="_blank" rel="noopener noreferrer">
                                <Button.Icon>
                                    <LinkOutIcon />
                                </Button.Icon>
                                <Button.Content>{link.title}</Button.Content>
                            </Button>
                        </Menu.Content.Item>
                    ))}
                </Menu.Content.List>
            </Menu.Content>
        </Menu>
    );
};
