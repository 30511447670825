import { Typography } from "@datamole/wds-component-typography";

import { ChatWithButton } from "@/components/chat-with-button";
import styles from "@/pages/page-not-found-page/page-not-found-page.module.css";

export const PageNotFoundPage = () => {
    return (
        <div className={styles.page_container}>
            <div className={styles.header}>
                <ChatWithButton />
            </div>
            <div className={styles.page_not_found_content}>
                <Typography as="h1" variant="5xl" weight="bold">
                    Not found
                </Typography>
                <Typography variant="base">Page you are looking for is not here.</Typography>
            </div>
        </div>
    );
};
