import { Route } from "@/routes/routes.types";

/**
 * Create routes object where each route has its path and params
 */
export const routes = {
    home: {
        path: "/",
    },
    chat: {
        path: "/chat/:sessionId",
    },
    trialEvents: {
        path: "/trial-events",
    },
    trialEventsCreate: {
        path: "/trial-events/create",
    },
    trialEventsDetail: {
        path: "/trial-events/:trialEventId",
    },
    trialEventsEdit: {
        path: "/trial-events/:trialEventId/edit",
    },
    scientificTesting: {
        path: "/scientific-testing",
    },
    pageNotFound: {
        path: "/page-not-found",
    },
} as const satisfies Record<string, Route>;

/**
 * Define Routes type representing all the defined routes
 */
export type Routes = typeof routes;

/**
 * Define RoutesPaths type union of all routes paths
 */
export type RoutesPaths = Routes[keyof Routes]["path"];
