import dayjsInLocalTime from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import isoWeek from "dayjs/plugin/isoWeek";
import relativeTime from "dayjs/plugin/relativeTime";
import utc from "dayjs/plugin/utc";

dayjsInLocalTime.extend(customParseFormat);
dayjsInLocalTime.extend(relativeTime);
dayjsInLocalTime.extend(utc);
dayjsInLocalTime.extend(isoWeek); // Needed for week to start on monday

const dayjs = dayjsInLocalTime.utc;

export { dayjs };
