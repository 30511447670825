import { Sidebar as WdsSidebar } from "@datamole/wds-component-sidebar";
import { clsx } from "clsx";
import { useMemo, useState } from "react";

import { Header } from "@/components/layout/sidebar/header";
import { QuickLinksMenu } from "@/components/layout/sidebar/quick-links-menu";
import { ReleaseNotes } from "@/components/layout/sidebar/release-notes/release-notes";
import { SidebarItem } from "@/components/layout/sidebar/sidebar-item";
import { CollapsibleSidebarItem } from "@/components/layout/sidebar/sidebar-item/collapsible-sidebar-item";
import { createAgentsSubitems, sidebarItems } from "@/components/layout/sidebar/sidebar-items";
import styles from "@/components/layout/sidebar/sidebar.module.css";
import { useScrollToSectionEffect } from "@/components/layout/sidebar/use-scroll-to-section-effect";
import { UserMenu } from "@/components/layout/sidebar/user-menu/user-menu";
import { useGetAgentsQuery } from "@/store/services/agent.service";

export const Sidebar = () => {
    const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);
    const { data: agents = [], error: agentsError, isLoading: isAgentsLoading } = useGetAgentsQuery();

    const agentsSubitems = useMemo(() => createAgentsSubitems(agents), [agents]);

    const toggleSidebarCollapsed = () => {
        setIsSidebarCollapsed((prev) => !prev);
    };

    if (agentsError) {
        // TODO: handle error properly
        console.error("Failed to fetch agents", agentsError);
    }

    useScrollToSectionEffect();

    return (
        <WdsSidebar className={clsx(styles.sidebar, { [styles.collapsed]: isSidebarCollapsed })}>
            <WdsSidebar.Content>
                <Header sidebarCollapsed={isSidebarCollapsed} toggleSidebarCollapsed={toggleSidebarCollapsed} />

                <SidebarItem {...sidebarItems.home} />

                <CollapsibleSidebarItem
                    subitems={agentsSubitems}
                    {...sidebarItems.agents}
                    isSubitemsLoading={isAgentsLoading}
                    isSidebarCollapsed={isSidebarCollapsed}
                    isInitiallyExpanded={true}
                />

                <SidebarItem {...sidebarItems.chatHistory} />

                <SidebarItem {...sidebarItems.scientificTesting} />

                <SidebarItem {...sidebarItems.trialEvents} />
            </WdsSidebar.Content>
            <WdsSidebar.Content className={styles.sidebar_footer}>
                <QuickLinksMenu />
                <ReleaseNotes />
                <UserMenu />
            </WdsSidebar.Content>
        </WdsSidebar>
    );
};
