import { GetAgentsResponseDto, getAgentsResponseSchema } from "@/api-schema/agent-schema";
import { api } from "@/store/api";
import { Agent } from "@/types/agent";

const ENDPOINT_PREFIX = "agent";

export const agentApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getAgents: builder.query<GetAgentsResponseDto, { includeFormAgents: boolean } | void>({
            query: (arg) => {
                const { includeFormAgents } = arg || {};
                return {
                    url: ENDPOINT_PREFIX,
                    method: "GET",
                    params: {
                        include_form_agents: includeFormAgents,
                    },
                };
            },
            transformResponse: (response): Agent[] => {
                const { data, error } = getAgentsResponseSchema.safeParse(response);
                if (error) {
                    console.error(error);
                    throw new Error("Failed to parse get agents response");
                }
                return data;
            },
        }),
    }),
});

export const { useGetAgentsQuery } = agentApi;
