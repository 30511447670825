import { Button, ButtonProps } from "@datamole/wds-component-button";
import { DialogContentActionsClose } from "@datamole/wds-component-dialog";

import { CloseIcon } from "@/components/icon";

type Props = ButtonProps;

const Close = (props: Props) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const { children = "Close", variant = "outline", startIcon = <CloseIcon />, ...otherProps } = props;

    return (
        <DialogContentActionsClose asChild>
            {/* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment */}
            <Button {...otherProps} startIcon={startIcon} variant={variant}>
                {children}
            </Button>
        </DialogContentActionsClose>
    );
};

export { Close as DialogContentActionsClose };
export type { Props as DialogContentActionsCloseProps };
