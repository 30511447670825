import React from "react";
import { createRoot } from "react-dom/client";

import { App } from "@/app";
import { config } from "@/config";

// eslint-disable-next-line no-console
console.log(`Fonterra ${config.APP_NAME} ${config.APP_VERSION} on ${config.APP_ENV} environment`);

const root = document.getElementById("root");
if (!root) {
    throw new Error("Root element not found");
}

createRoot(root).render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
);
