import { Button } from "@datamole/wds-component-button";
import { Typography } from "@datamole/wds-component-typography";
import { clsx } from "clsx";
import { Link } from "react-router-dom";

import styles from "@/components/chat-with-button/chat-with-button.module.css";
import { AddIcon } from "@/components/icon";
import { config } from "@/config";
import { HOMEPAGE_CHAT_ID, SCROLL_TO_PARAM_KEY } from "@/constants";
import { routes } from "@/routes";
import { constructPath } from "@/utils/construct-path";

export const ChatWithButton = () => {
    return (
        <Button
            as={Link}
            to={constructPath({
                path: routes.home.path,
                queryParams: { [SCROLL_TO_PARAM_KEY]: HOMEPAGE_CHAT_ID },
            })}
            className={clsx("rd_button", styles.chat_with_button)}
        >
            <Button.Icon>
                <AddIcon className={styles.chat_with_icon} />
            </Button.Icon>
            <Button.Content>
                <Typography weight="bold">Chat with {config.APP_NAME}</Typography>
            </Button.Content>
        </Button>
    );
};
