import { useAzureAuth } from "@datamole/wds-azure-auth";
import { Button } from "@datamole/wds-component-button";
import { Menu } from "@datamole/wds-component-menu";
import { Sidebar } from "@datamole/wds-component-sidebar";

import { LogoutIcon, UserMenuIcon } from "@/components/icon";
import styles from "@/components/layout/sidebar/user-menu/user-menu.module.css";

const UserMenu = () => {
    const { triggerLogout, user } = useAzureAuth();

    return (
        <Menu>
            <Menu.Trigger asChild className={`rd_button`}>
                <Sidebar.Item>
                    <Sidebar.Item.Icon>
                        <UserMenuIcon className={styles.user_menu_icon} />
                    </Sidebar.Item.Icon>
                    <Sidebar.Item.Content>{user?.name ?? user?.username}</Sidebar.Item.Content>
                </Sidebar.Item>
            </Menu.Trigger>
            <Menu.Content
                align="end"
                side="right"
                onOpenAutoFocus={(ev) => {
                    ev.preventDefault();
                }}
            >
                <Menu.Content.Item asChild>
                    <Button onClick={triggerLogout}>
                        <Button.Icon>
                            <LogoutIcon />
                        </Button.Icon>
                        <Button.Content>Log out</Button.Content>
                    </Button>
                </Menu.Content.Item>
            </Menu.Content>
        </Menu>
    );
};

export { UserMenu };
