import { Alert } from "@datamole/wds-component-alert";
import { DialogContentBody, DialogContentBodyProps } from "@datamole/wds-component-dialog";
import { type ReactNode } from "react";

type Props = DialogContentBodyProps & {
    title?: string;
    children?: ReactNode;
};

const DialogContentBodyError = (props: Props) => {
    const { children, title, ...otherProps } = props;

    return (
        <DialogContentBody {...otherProps}>
            <Alert title={title} severity="error">
                {children}
            </Alert>
        </DialogContentBody>
    );
};

export { DialogContentBodyError };
export type { Props as DialogContentBodyErrorProps };
