import { createBrowserRouter, RouterProvider } from "react-router-dom";

import { AuthGuard } from "@/components/auth-guard";
import { Layout } from "@/components/layout";
import { UserConsentGuard } from "@/components/user-consent-guard";
import { pageAtRouteMap } from "@/pages";
import PageNotFoundPage from "@/pages/page-not-found-page";
import { routeNames, routes } from "@/routes";

/**
 * Create an array of objects with path and component properties
 */
const pages = routeNames.map((routeName) => {
    const Component = pageAtRouteMap[routeName];
    return {
        path: routes[routeName].path,
        element: <Component />,
    };
});

// Define router using createBrowserRouter
const router = createBrowserRouter([
    {
        element: (
            <AuthGuard>
                <UserConsentGuard>
                    <Layout />
                </UserConsentGuard>
            </AuthGuard>
        ),
        children: [
            ...pages, // Spread generated routes
            { path: "*", element: <PageNotFoundPage /> }, // 404 fallback
        ],
    },
]);

export const AppRoutes = () => <RouterProvider router={router} />;
