import { DialogContentHeader, DialogContentHeaderProps } from "@datamole/wds-component-dialog";

type Props = DialogContentHeaderProps;

export const Header = (props: Props) => {
    return <DialogContentHeader {...props} />;
};

export { Header as DialogContentHeader };
export type { Props as DialogContentHeaderProps };
