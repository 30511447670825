import { configureStore } from "@reduxjs/toolkit";

import { api } from "@/store/api";
import { authReducer } from "@/store/slices/auth.slice";
import { pinChatReducer } from "@/store/slices/pin-chat.slice";
import { promptReducer } from "@/store/slices/prompt.slice";

/**
 * Create and configure redux store using the `configureStore` (more info: https://redux.js.org/usage/configuring-your-store)
 */
export const store = configureStore({
    reducer: {
        [api.reducerPath]: api.reducer,
        auth: authReducer,
        pinChat: pinChatReducer,
        prompt: promptReducer,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(api.middleware), // explicitly use `getDefaultMiddleware()`
    enhancers: (getDefaultEnhancers) => getDefaultEnhancers(), // explicitly use `getDefaultEnhancers()`
});

/**
 * Infer the `RootState` type from the store
 */
export type RootState = ReturnType<typeof store.getState>;
/**
 * Infer the `AppDispatch` type from the store
 */
export type AppDispatch = typeof store.dispatch;
