import { Button } from "@datamole/wds-component-button";
import { Sidebar } from "@datamole/wds-component-sidebar";

import { MenuIcon, MenuCloseIcon } from "@/components/icon";
import styles from "@/components/layout/sidebar/header/header.module.css";

interface Props {
    sidebarCollapsed: boolean;
    toggleSidebarCollapsed: () => void;
}

export const Header = ({ sidebarCollapsed, toggleSidebarCollapsed }: Props) => (
    <Sidebar.Header className={styles.header}>
        <Button
            className="rd_button"
            onClick={() => {
                toggleSidebarCollapsed();
            }}
        >
            <Button.Icon className={styles.expand_collapse_icon}>
                {sidebarCollapsed ? <MenuIcon alt="Expand Sidebar" /> : <MenuCloseIcon alt="Collapse Sidebar" />}
            </Button.Icon>
        </Button>
    </Sidebar.Header>
);
