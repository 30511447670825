import { useEffect, useState } from "react";
import { z } from "zod";

import { dayjs } from "@/utils/dayjs";

interface UserConsentData {
    userId: string;
    isConsentGiven: boolean;
    consentTime: string;
}

const userConsentSchema = z.object({
    userId: z.string(),
    isConsentGiven: z.boolean(),
    consentTime: z.string().refine((val) => dayjs(val).isValid()),
});

export const useUserConsentGuard = (userId: string) => {
    const storageKey = `userConsent_${userId}`;
    const [isConsentGiven, setIsConsentGiven] = useState(true);

    useEffect(() => {
        const storedConsent = localStorage.getItem(storageKey);

        if (!storedConsent) {
            setIsConsentGiven(false);
            return;
        }

        try {
            const consentData = JSON.parse(storedConsent) as unknown;
            const validation = userConsentSchema.safeParse(consentData);

            if (validation.success) {
                setIsConsentGiven(validation.data.isConsentGiven);
            } else {
                console.error("Invalid consent data format:", validation.error);
                setIsConsentGiven(false);
            }
        } catch (error) {
            console.error("Failed to parse user consent from localStorage", error);
        }
    }, [storageKey]);

    const setConsent = () => {
        const consentData: UserConsentData = {
            userId,
            isConsentGiven: true,
            consentTime: dayjs().toISOString(),
        };

        localStorage.setItem(storageKey, JSON.stringify(consentData));
        setIsConsentGiven(true);
    };

    return { isConsentGiven, setConsent };
};
