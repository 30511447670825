import { DialogContentBody, DialogContentBodyProps } from "@datamole/wds-component-dialog";
import { type ReactNode } from "react";

import { Spinner } from "@/components/spinner";

type Props = DialogContentBodyProps & {
    title?: string;
    children?: ReactNode;
};

const DialogContentBodyLoading = (props: Props) => {
    const { ...otherProps } = props;

    return (
        <DialogContentBody {...otherProps}>
            <Spinner size="md" />
        </DialogContentBody>
    );
};

export { DialogContentBodyLoading };
export type { Props as DialogContentBodyLoadingProps };
