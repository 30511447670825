import {
    faRightFromBracket,
    faCopy,
    faCheck,
    faCloudDownload,
    faPencil,
    faArrowLeft,
    faCompass,
    faExternalLink,
    faUserCircle as faUserCircleRegular,
    faThumbsDown as faThumbsDownRegular,
    faThumbsUp as faThumbsUpRegular,
    faThumbtack as faThumbtackRegular,
    faFilePen,
} from "@fortawesome/pro-regular-svg-icons";
import {
    faCirclePlus,
    faCalendarClock,
    faHouse,
    faMicroscope,
    faUserHeadset,
    faPaperPlaneTop,
    faTimes,
    faTrashCan,
    faFileArrowDown,
    faPrint,
    faUserCircle as faUserCircleSolid,
    faThumbsDown as faThumbsDownSolid,
    faThumbsUp as faThumbsUpSolid,
    faThumbtack as faThumbtackSolid,
    faCircleInfo,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon as FaIcon } from "@fortawesome/react-fontawesome";
import { MouseEventHandler } from "react";

import { chatGreen, chatProfile, menu, menuClose, message } from "@/components/icon/svg-icons";

interface Props {
    alt?: string;
    className?: string;
    onClick?: MouseEventHandler<SVGElement | HTMLImageElement>;
}

const createProps = (props: Props) => ({
    "aria-label": props.alt,
    ...props,
});

export const AddIcon = (props: Props) => <FaIcon icon={faCirclePlus} {...createProps(props)} />;
export const AgentIcon = (props: Props) => <FaIcon icon={faUserHeadset} {...createProps(props)} />;
export const AssistantMessageIcon = (props: Props) => <img src={chatProfile} {...createProps(props)} />;
export const CloseIcon = (props: Props) => <FaIcon icon={faTimes} {...createProps(props)} />;
export const CopyIcon = (props: Props) => <FaIcon icon={faCopy} {...createProps(props)} />;
export const DeleteIcon = (props: Props) => <FaIcon icon={faTrashCan} {...createProps(props)} />;
export const DisclaimerInfoIcon = (props: Props) => <FaIcon icon={faCircleInfo} {...createProps(props)} />;
export const DownloadAllIcon = (props: Props) => <FaIcon icon={faFileArrowDown} {...createProps(props)} />;
export const DownloadIcon = (props: Props) => <FaIcon icon={faCloudDownload} {...createProps(props)} />;
export const HomeIcon = (props: Props) => <FaIcon icon={faHouse} {...createProps(props)} />;
export const ChatCardIcon = (props: Props) => <img src={chatGreen} {...createProps(props)} />;
export const CheckIcon = (props: Props) => <FaIcon icon={faCheck} {...createProps(props)} />;
export const LinkOutIcon = (props: Props) => <FaIcon icon={faExternalLink} {...createProps(props)} />;
export const LogoutIcon = (props: Props) => <FaIcon icon={faRightFromBracket} {...createProps(props)} />;
export const MenuCloseIcon = (props: Props) => <img src={menuClose} {...createProps(props)} />;
export const MenuIcon = (props: Props) => <img src={menu} {...createProps(props)} />;
export const MessageIcon = (props: Props) => <img src={message} {...createProps(props)} />;
export const NavigateBackIcon = (props: Props) => <FaIcon icon={faArrowLeft} {...createProps(props)} />;
export const NegativeFeedbackActiveIcon = (props: Props) => <FaIcon icon={faThumbsDownSolid} {...createProps(props)} />;
export const NegativeFeedbackIcon = (props: Props) => <FaIcon icon={faThumbsDownRegular} {...createProps(props)} />;
export const PinActiveIcon = (props: Props) => <FaIcon icon={faThumbtackSolid} {...createProps(props)} />;
export const PinIcon = (props: Props) => <FaIcon icon={faThumbtackRegular} {...createProps(props)} />;
export const PositiveFeedbackActiveIcon = (props: Props) => <FaIcon icon={faThumbsUpSolid} {...createProps(props)} />;
export const PositiveFeedbackIcon = (props: Props) => <FaIcon icon={faThumbsUpRegular} {...createProps(props)} />;
export const PrintIcon = (props: Props) => <FaIcon icon={faPrint} {...createProps(props)} />;
export const QuickLinkIcon = (props: Props) => <FaIcon icon={faCompass} {...createProps(props)} />;
export const ReleaseNotesIcon = (props: Props) => <FaIcon icon={faFilePen} {...createProps(props)} />;
export const ReusePromptIcon = (props: Props) => <FaIcon icon={faPencil} {...createProps(props)} />;
export const ScientificTestingIcon = (props: Props) => <FaIcon icon={faMicroscope} {...createProps(props)} />;
export const SubmitIcon = (props: Props) => <FaIcon icon={faPaperPlaneTop} {...createProps(props)} />;
export const TrialEventsIcon = (props: Props) => <FaIcon icon={faCalendarClock} {...createProps(props)} />;
export const UserMenuIcon = (props: Props) => <FaIcon icon={faUserCircleRegular} {...createProps(props)} />;
export const UserMessageIcon = (props: Props) => <FaIcon icon={faUserCircleSolid} {...createProps(props)} />;
