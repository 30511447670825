import { useAzureAuth } from "@datamole/wds-azure-auth";
import { Button } from "@datamole/wds-component-button";
import { Typography } from "@datamole/wds-component-typography";
import { ReactNode } from "react";

import { Dialog } from "@/components/dialog";
import styles from "@/components/user-consent-guard/user-consent-guard.module.css";
import { DISPLAY_DATE_FORMAT } from "@/constants";
import { useUserConsentGuard } from "@/hooks/use-user-consent-guard";
import { dayjs } from "@/utils/dayjs";

interface Props {
    children: ReactNode;
}

export const UserConsentGuard = ({ children }: Props) => {
    const { user } = useAzureAuth();

    if (!user) {
        throw new Error("User is not authenticated while using pin chat feature. This should not happen.");
    }

    const { isConsentGiven, setConsent } = useUserConsentGuard(user.homeAccountId);

    if (!isConsentGiven) {
        return (
            <Dialog open={true}>
                <Dialog.Content>
                    {/**
                     * Workaround to discard radix UI accessibility error.
                     * TODO: Update WDS Dialog component so the close button can be omitted.
                     */}
                    <Dialog.Content.Header hidden />

                    <div className={styles.header}>Content Use Statement</div>
                    <Dialog.Content.Body>
                        <div className={styles.element_dialog_body}>
                            <Typography>
                                The information generated using ARDI is considered as Confidential to FRDC. Do not share
                                any information generated in ARDI beyond FRDC. If you need to use the generated content
                                in communication or reports beyond FRDC, please contact your Line Manager.
                            </Typography>
                            <Typography>
                                Note: This statement will need to be accepted annually on{" "}
                                {dayjs().format(DISPLAY_DATE_FORMAT)}.
                            </Typography>
                        </div>
                    </Dialog.Content.Body>
                    <Dialog.Content.Actions>
                        <Button variant="primary" onClick={setConsent}>
                            I acknowledge this statement
                        </Button>
                    </Dialog.Content.Actions>
                </Dialog.Content>
            </Dialog>
        );
    }

    return <>{children}</>;
};
