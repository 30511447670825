import { z } from "zod";

export const getAgentResponseSchema = z.object({
    id: z.string(),
    name: z.string(),
    isFormAgent: z.boolean(),
    description: z.string().nullable(),
    messageFieldText: z.string().nullable(),
});

export const getAgentsResponseSchema = z.array(getAgentResponseSchema);

export type GetAgentsResponseDto = z.infer<typeof getAgentsResponseSchema>;
