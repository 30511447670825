import {
    DialogRoot,
    DialogTrigger,
    DialogContentBody,
    DialogContent,
    DialogContentActions,
} from "@datamole/wds-component-dialog";

import { DialogContentActionsClose } from "@/components/dialog/dialog-content-actions-close";
import { DialogContentBodyError } from "@/components/dialog/dialog-content-body-error";
import { DialogContentBodyLoading } from "@/components/dialog/dialog-content-body-loading";
import { DialogContentHeader } from "@/components/dialog/dialog-content-header";

const Dialog = Object.assign(DialogRoot, {
    Trigger: DialogTrigger,
    Content: Object.assign(DialogContent, {
        Header: DialogContentHeader,
        Body: DialogContentBody,
        BodyLoading: DialogContentBodyLoading,
        BodyError: DialogContentBodyError,
        Actions: Object.assign(DialogContentActions, {
            Close: DialogContentActionsClose,
        }),
    }),
});

export { Dialog };
