import { TrialEventsIcon, HomeIcon, ScientificTestingIcon, AgentIcon, MessageIcon } from "@/components/icon";
import { SidebarItemProps } from "@/components/layout/sidebar/sidebar-item";
import { AGENT_ID_PARAM_KEY, HOMEPAGE_CHAT_HISTORY_ID, HOMEPAGE_CHAT_ID, SCROLL_TO_PARAM_KEY } from "@/constants";
import { routes } from "@/routes";
import { Agent } from "@/types/agent";
import { constructPath } from "@/utils/construct-path";

export const createAgentsSubitems = (agents: Agent[]): SidebarItemProps[] =>
    agents.map((agent) => ({
        to: constructPath({
            path: routes.home.path,
            queryParams: {
                [SCROLL_TO_PARAM_KEY]: HOMEPAGE_CHAT_ID,
                [AGENT_ID_PARAM_KEY]: agent.id,
            },
        }),
        name: agent.name,
        icon: <AgentIcon />,
        iconVariant: "light",
        isActive: (url: URL) => {
            const searchParams = new URLSearchParams(url.search);
            const currentAgentId = searchParams.get(AGENT_ID_PARAM_KEY);
            return currentAgentId === agent.id;
        },
    }));

export const sidebarItems = {
    home: {
        to: constructPath({
            path: routes.home.path,
            queryParams: { [SCROLL_TO_PARAM_KEY]: HOMEPAGE_CHAT_ID },
        }),
        name: "Home",
        icon: <HomeIcon />,
    },
    agents: {
        to: routes.home.path,
        name: "Agents",
        icon: <AgentIcon />,
        isActive: () => false,
    },
    chatHistory: {
        to: constructPath({
            path: routes.home.path,
            queryParams: { [SCROLL_TO_PARAM_KEY]: HOMEPAGE_CHAT_HISTORY_ID },
        }),
        name: "Chat History",
        icon: <MessageIcon />,
        isActive: () => false,
    },
    trialEvents: {
        to: routes.trialEvents.path,
        name: "Trial Events",
        icon: <TrialEventsIcon />,
    },
    scientificTesting: {
        to: routes.scientificTesting.path,
        name: "Scientific Testing",
        icon: <ScientificTestingIcon />,
    },
} satisfies Record<string, SidebarItemProps>;
