import { Sidebar } from "@datamole/wds-component-sidebar";

import { ReleaseNotesIcon } from "@/components/icon";
import styles from "@/components/layout/sidebar/release-notes/release-notes.module.css";
import { config } from "@/config";

export const ReleaseNotes = () => {
    return (
        <Sidebar.Item
            href={config.APP_RELEASE_NOTES_URL}
            target="_blank"
            rel="noreferrer noopener"
            className="rd_button"
        >
            <Sidebar.Item.Icon>
                <ReleaseNotesIcon className={styles.icon} />
            </Sidebar.Item.Icon>
            <Sidebar.Item.Content className={styles.label}>
                Release Notes
                <span className={styles.version_text}>{config.APP_VERSION}</span>
            </Sidebar.Item.Content>
        </Sidebar.Item>
    );
};
